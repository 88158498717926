import React, { Component } from "react";
import "./SignForm.scss";
import api from "../../services/api";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../utility/constants";

import emailjs from "@emailjs/browser";

const standardMessage = {
  nome: "",
  email: "",
  telefone: "",
  curso: "",
  unidade: "",
  assunto: "Inscrição Online",
  hasOnlineClasses: false,
};

class SignForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: [],
      showPopUp: false,
      enableSubmit: false,
      disableOptionOnline: false,
      message: standardMessage,
    };
  }

  componentDidMount() {
    this.loadCourses();
    this.state.disableOptionOnline = this.props.hasOnlineClasses;
  }

  onChange = (value) => {
    this.setState({ enableSubmit: true });
  };

  loadCourses = async () => {
    try {
      const response = await api.get(`courses/index`);
      const courses = response.data;
      this.setState({ courses });
    } catch (err) {
      console.log(err);
    }
  };

  renderCourses = () => {
    const { courses } = this.state || [{ id: 1, title: "titulo" }];
    return courses.map((course) => (
      <option key={course.id} value={course.title}>
        {course.title}
      </option>
    ));
  };

  renderAges = () => {
    const ages = [
      { id: 1, range: "5 - 10 anos" },
      { id: 2, range: "11 - 20 anos" },
      { id: 3, range: "21 - 30 anos" },
    ];
    return ages.map((age) => (
      <option key={age.id} value={age.id}>
        {age.range}
      </option>
    ));
  };

  handleChange = (event) => {
    const value = event.target.value;

    switch (event.target.id) {
      case "nome":
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            nome: value,
          },
        }));
        break;
      case "email":
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            email: value,
          },
        }));
        break;
      case "telefone":
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            telefone: value,
          },
        }));
        break;
      case "select-unidade":
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            unidade: value,
          },
        }));
        break;
      case "select-curso":
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            curso: value,
          },
        }));
        break;
      default:
        break;
    }
  };

  clearData = async (event) => {
    this.setState((prevState) => ({
      message: standardMessage,
    }));

    this.setState((prevState) => ({
      message: {
        ...prevState.message,
      },
      showPopUp: true,
    }));

    setTimeout(
      function () {
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
          },
          showPopUp: false,
        }));
      }.bind(this),
      3000
    );
  };

  setEmail = async (email) => {
    this.setState((prevState) => ({
      message: {
        ...prevState.message,
        unidade: email,
      },
    }));
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const emailUnidade = this.props.email;

    await this.setEmail(emailUnidade);

    const message = this.state.message;

    message.assunto = `${message.assunto} - Categoria ${
      message.hasOnlineClasses ? "Online" : "Presencial"
    }`;
    message.hasOnlineClasses = false; // message.hasOnlineClasses && !this.props.unit;

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        "template_sz8egza",
        message,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      this.clearData();
    } catch (err) {
      console.log(err);
    }
  };

  setTypeModality = (event) => {
    const value = event.target.value === "true";

    this.setState((prevState) => ({
      message: {
        ...prevState.message,
        hasOnlineClasses: value,
      },
    }));
  };

  // forceLower(strInput) {
  //   strInput.value=strInput.value.toLowerCase();
  // }​

  render() {
    const recaptchaRef = React.createRef();
    const { message, disableOptionOnline } = this.state;

    return (
      <div className="inscricao">
        <form onSubmit={this.handleSubmit}>
          <p className="title-sign">Cadastrar-se e saiba mais</p>

          <input
            type="text"
            required
            placeholder="NOME"
            id="nome"
            name="nome"
            onChange={this.handleChange}
            value={this.state.message.nome}
          />
          <input
            type="email"
            required
            placeholder="E-MAIL"
            id="email"
            name="email"
            onChange={this.handleChange}
            value={this.state.message.email}
          />
          <input
            type="phone"
            required
            placeholder="TELEFONE"
            id="telefone"
            name="telefone"
            onChange={this.handleChange}
            value={this.state.message.telefone}
          />
          <input
            type="hidden"
            placeholder="EMAIL"
            id="email-unidade"
            name="email-unidade"
            required
            value={this.props.email}
          />
          <div className="select-container">
            <select
              required
              className="select-inscricao"
              name="curso"
              id="select-curso"
              onChange={this.handleChange}
              value={this.state.message.curso}
            >
              <option value="" disabled selected>
                CURSO
              </option>
              {this.renderCourses()}
            </select>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div class="radio-toolbar">
                <input
                  type="radio"
                  id="radioPresential"
                  name="radioClass"
                  value="false"
                  checked={message.hasOnlineClasses === false}
                  onChange={this.setTypeModality}
                />
                <label for="radioPresential">AULA PRESENCIAL</label>

                <input
                  type="radio"
                  id="radioOnline"
                  name="radioClass"
                  value="true"
                  checked={message.hasOnlineClasses === true}
                  onChange={this.setTypeModality}
                  // disabled={!this.props.hasOnlineClasses}
                />
                <label for="radioOnline">AULA ONLINE</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="submit-button">
                <button
                  className={
                    this.state.enableSubmit === false
                      ? "d-none"
                      : "button-inscricao"
                  }
                >
                  <p>Enviar</p>
                </button>
              </div>
            </div>
          </div>
          <div className="submit-container">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={recaptchaKey}
              onChange={this.onChange}
              size="normal"
            />
            <div
              className={
                this.state.showPopUp === false
                  ? "d-none"
                  : "popup-discount-alert"
              }
            >
              <div>Mensagem enviada!</div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default SignForm;
