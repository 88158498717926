import React, { Component } from "react";
import "./Cursos.scss";
import api from "../../services/api";
import Nav from "../../components/Nav/Nav";
import Banner from "../../components/Banner/Banner";
import Valencia from "../../components/Valencia/Valencia";
import Contact from "../../components/Contact/Contact";
import Languages from "../../components/Languages/Languages";
import Unit from "../../components/Unit/Unit";
import Topbar from "../../components/Topbar/Topbar";
import Footer from "../../components/Footer/Footer";
import Directions from "../../components/Directions";

class CursosIndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unit: {
        address: {},
        images: [],
        cover: {},
        has_online_classes: false,
      },
    };
  }

  componentDidMount() {
    this.loadUnit();
  }

  loadUnit = async () => {
    try {
      const response = await api.get(`units/${this.props.match.params.id}`);
      const unit = response.data;
      this.setState({ unit });
    } catch (err) {
      window.location.assign("/");
    }
  };

  render() {
    return (
      <div className="">
        <Topbar unit={this.state.unit.title} />
        <Nav
          sobre="false"
          // unit="unidade"
          unit={this.props.match.params.id}
          cursosUnidade={`${this.props.match.params.id}`}
          blogUnidade={`${this.props.match.params.id}`}
        />
        {/* <SlideShow /> */}
        <Banner
          hasOnlineClasses={this.state.unit.has_online_classes}
          cover={this.state.unit.cover.url}
          email={this.state.unit.email_message}
          unit={this.props.match.params.id}
        />
        <Languages unit={this.props.match.params.id} />
        <Unit
          unit={this.state.unit.title}
          street={this.state.unit.address.street}
          city={this.state.unit.address.city}
          state={this.state.unit.address.state}
          number={this.state.unit.address.number}
          neighborhood={this.state.unit.address.neighborhood}
          email={this.state.unit.email}
          phone={this.state.unit.phone}
          site={this.state.unit.site}
          gallery={this.state.unit.images}
        />
        <Valencia unit={this.state.unit.title} />
        <Contact
          unitEmail={this.state.unit.email_message}
          phone={this.state.unit.phone}
          latitude={this.state.unit.address.latitude}
          longitude={this.state.unit.address.longitude}
          idUnidade={this.props.match.params.id}
        />
        {/* <Directions
          unitEmail={this.state.unit.email_message}
          phone={this.state.unit.phone}
          latitude={this.state.unit.address.latitude}
          longitude={this.state.unit.address.longitude}
          idUnidade={this.props.match.params.id}
        /> */}
        <Footer />
      </div>
    );
  }
}

export default CursosIndexPage;
