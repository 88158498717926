import React, { Component } from "react";
import "./Agende.scss";
import api from "../../services/api";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../utility/constants";
import emailjs from "@emailjs/browser";

class Agende extends Component {
  constructor(props) {
    super(props);

    this.state = {
      units: [],
      courses: [],
      enableSubmit: false,
      showPopUp: false,
      disableInputUnit: false,
      message: {
        nome: "",
        email: "",
        telefone: "",
        curso: "",
        unidade: "",
        hasOnlineClasses: false,
      },
    };
  }

  componentDidMount() {
    this.loadUnits();
    this.loadCourses();
  }

  loadCourses = async () => {
    try {
      const response = await api.get(`courses/index`);
      const courses = response.data;

      this.setState({ courses });
    } catch (err) {
      console.log(err);
    }
  };

  renderCourses = () => {
    const { courses } = this.state;

    return courses.map((course, index) => (
      <option key={index} value={course.title}>
        {course.title}
      </option>
    ));
  };

  loadUnits = async () => {
    try {
      const response = await api.get(`units/index`);
      const units = response.data;
      this.setState({ units });
    } catch (err) {
      console.log(err);
    }
  };

  renderUnits = () => {
    const { units } = this.state;

    return units.map((unit, index) => (
      <option key={index} value={unit.email_message || unit.email}>
        {unit.title}
      </option>
    ));
  };

  handleChange = (event) => {
    const value = event.target.value;

    switch (event.target.id) {
      case "nome":
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            nome: value,
          },
        }));
        break;
      case "email":
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            email: value,
          },
        }));
        break;
      case "telefone":
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            telefone: value,
          },
        }));
        break;
      case "select-unidade":
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            unidade: value,
          },
        }));
        break;
      case "select-curso":
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            curso: value,
          },
        }));
        break;

      default:
        break;
    }
  };

  // handler do captcha
  onChange = (value) => {
    this.setState({ enableSubmit: true });
  };

  clearData = async (event) => {
    this.setState((prevState) => ({
      message: {
        nome: "",
        email: "",
        telefone: "",
        unidade: "",
        curso: "",
      },
    }));
    this.setState({ showPopUp: true });

    setTimeout(
      function () {
        this.setState({ showPopUp: false });
      }.bind(this),
      3000
    );
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ enableSubmit: false });
    const message = this.state.message;

    try {
      message.assunto = `FAÇA UMA AULA GRÁTIS - Categoria ${
        message.hasOnlineClasses ? "Online" : "Presencial"
      }`;
      message.hasOnlineClasses = false; // message.hasOnlineClasses && !this.props.unit;

      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        "template_sz8egza",
        message,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      this.clearData();
    } catch (err) {
      console.log(err);
    }

    this.setState({ enableSubmit: true });
  };

  setTypeModality = (event) => {
    const value = event.target.value === "true";
    let message = {
      hasOnlineClasses: value,
    };

    // if (value) {
    // 	message['unidade'] = "";
    // }

    this.setState((prevState) => ({
      disableInputUnit: value,
      message: {
        ...prevState.message,
        ...message,
      },
    }));
  };

  render() {
    const recaptchaRef = React.createRef();
    const { message, disableInputUnit } = this.state;

    return (
      <>
        <div id="agende">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-container">
                  <p className="agende">
                    CONHEÇA UM JEITO ABSOLUTAMENTE PRÁTICO, DIVERTIDO E RÁPIDO
                    DE APRENDER UM NOVO IDIOMA
                  </p>
                  <p className="viva">Agende agora sua experiência IBL</p>
                  <div id="box-text-border">
                    <p className="agende">FAÇA UMA AULA GRÁTIS</p>
                  </div>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="col-md-12">
                    <div className="form-agende">
                      <input
                        type="text"
                        className="form-contact-input"
                        required
                        id="nome"
                        name="nome"
                        placeholder="NOME"
                        onChange={this.handleChange}
                        value={this.state.message.nome}
                      />
                      <input
                        type="text"
                        className="form-contact-input"
                        required
                        id="email"
                        name="email"
                        placeholder="EMAIL"
                        onChange={this.handleChange}
                        value={this.state.message.email}
                      />
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-contact-input"
                            id="telefone"
                            required
                            name="telefone"
                            placeholder="TELEFONE"
                            onChange={this.handleChange}
                            value={this.state.message.telefone}
                          />
                        </div>
                        <div className="col-md-6">
                          <select
                            className="form-contact-input"
                            required
                            name="unidade"
                            id="select-unidade"
                            onChange={this.handleChange}
                            value={this.state.message.unidade}
                            // disabled={disableInputUnit}
                          >
                            <option value="" defaultValue>
                              UNIDADE
                            </option>
                            {this.renderUnits()}
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <select
                            className="form-contact-input"
                            name="curso"
                            required
                            id="select-curso"
                            onChange={this.handleChange}
                            value={this.state.message.curso}
                          >
                            <option value="" disabled defaultValue>
                              CURSO
                            </option>
                            {this.renderCourses()}
                          </select>
                        </div>
                        <div className="col-md-6">
                          <div class="radio-toolbar">
                            <input
                              type="radio"
                              id="radioPresential"
                              name="radioFruit"
                              value="false"
                              checked={message.hasOnlineClasses === false}
                              onChange={this.setTypeModality}
                            />
                            <label for="radioPresential">AULA PRESENCIAL</label>

                            <input
                              type="radio"
                              id="radioOnline"
                              name="radioFruit"
                              value="true"
                              checked={message.hasOnlineClasses === true}
                              onChange={this.setTypeModality}
                            />
                            <label for="radioOnline">AULA ONLINE</label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={recaptchaKey}
                            onChange={this.onChange}
                            size="normal"
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="box-submit-button">
                            <button
                              className={
                                this.state.enableSubmit === false
                                  ? "d-none"
                                  : "form-contact-submit"
                              }
                            >
                              Enviar
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="submit-container">
                        <div
                          className={
                            this.state.showPopUp === false
                              ? "d-none"
                              : "popup-alert"
                          }
                        >
                          <div>Mensagem enviada com sucesso!</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Agende;
