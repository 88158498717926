import React, { Component } from "react";
import "./DiscountForm.scss";
import api from "../../services/api";
import Accordion from "react-bootstrap/Accordion";
// import Card from 'react-bootstrap/Card'
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../utility/constants";
import emailjs from "@emailjs/browser";

class DiscountForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      units: [],
      showPopUp: false,
      enableSubmit: false,
      disableOptionOnline: false,
      disableInputUnit: false,
      message: {
        nome: "",
        email: "",
        telefone: "",
        unidade: "",
        hasOnlineClasses: false,
        assunto: "Formulário de desconto",
      },
      submited: false,
      openForm: "0",
    };
  }

  handleClickOnOpenForm = () => {
    this.setState({ ...this.state, openForm: "1" });
  };

  loadUnits = async () => {
    try {
      const response = await api.get(`units/index`);
      const units = response.data;
      const currentUnit = units.find(
        (element) => element.slug === this.props.unit
      );

      this.setState((prevState) => ({
        units,
        message: {
          ...prevState.message,
          unidade: currentUnit
            ? currentUnit.email_message || currentUnit.email
            : "",
        },
      }));
      this.verifyWasOnlineClassUnit(units);
    } catch (err) {
      console.log(err);
    }
  };

  componentDidMount() {
    this.loadUnits();
  }

  renderUnits = () => {
    const { units } = this.state;

    return units.map((unit) => (
      <option key={unit.id} value={unit.email_message}>
        {unit.title}
      </option>
    ));
  };

  handleChange = (event) => {
    const value = event.target.value;
    const { units } = this.state;

    switch (event.target.id) {
      case "d-nome":
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            nome: value,
          },
        }));
        break;
      case "d-email":
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            email: value,
          },
        }));
        break;
      case "d-telefone":
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            telefone: value,
          },
        }));
        break;
      case "d-select-unidade":
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            unidade: value,
          },
        }));
        break;
      case "curso":
        this.setState((prevState) => ({
          message: {
            ...prevState.message,
            curso: value,
          },
        }));
        break;
      default:
        break;
    }
  };

  setTypeModalityDiscount = (event) => {
    const value = event.target.value === "true";

    let message = {
      hasOnlineClasses: value,
    };

    this.setState((prevState) => ({
      disableInputUnit: value,
      message: {
        ...prevState.message,
        ...message,
      },
    }));
  };

  clearData = async (event) => {
    const currentUnit = this.state.units.find(
      (element) => element.slug === this.props.unit
    );

    this.setState((prevState) => ({
      message: {
        nome: "",
        email: "",
        telefone: "",
        unidade:
          currentUnit && this.props.unit
            ? currentUnit.email_message || currentUnit.email
            : "",
        curso: "",
        hasOnlineClasses: false,
      },
    }));
    this.setState({ showPopUp: true });

    setTimeout(
      function () {
        this.setState({ showPopUp: false });
      }.bind(this),
      3000
    );
  };

  onChange = (value) => {
    this.setState({ enableSubmit: true });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const message = this.state.message;
    this.setState({ submited: true });

    try {
      message.assunto = `${message.assunto || ""} - Categoria ${
        message.hasOnlineClasses ? "Online" : "Presencial"
      }`;
      message.hasOnlineClasses = false; // message.hasOnlineClasses && !this.props.unit;

      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        "template_sz8egza",
        message,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      this.clearData();
      this.setState({ submited: false });
    } catch (err) {
      console.log(err);
    }
  };

  verifyWasOnlineClassUnit(units) {
    let unit = units.find((element) => element.slug === this.props.unit);
    if (unit) {
      unit["hasOnlineClasses"] = false;
      this.setState({
        disableOptionOnline: !unit["hasOnlineClasses"],
      });
    }
  }

  render() {
    const recaptchaRef = React.createRef();
    const { message, disableOptionOnline, disableInputUnit } = this.state;

    return (
      <div id="discount">
        <Accordion defaultActiveKey="1">
          <div>
            <Accordion.Toggle eventKey="0">
              <div>
                <p className="cadastre">Cadastre-se e ganhe</p>
                <p className="desconto">20% DE DESCONTO</p>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <div className="form-container">
                <form id="discount-form" onSubmit={this.handleSubmit}>
                  <input
                    type="text"
                    required
                    placeholder="NOME"
                    id="d-nome"
                    name="nome"
                    onChange={this.handleChange}
                    value={this.state.message.nome}
                  />
                  <input
                    required
                    type="email"
                    placeholder="EMAIL"
                    id="d-email"
                    name="email"
                    onChange={this.handleChange}
                    value={this.state.message.email}
                  />
                  <input
                    type="phone"
                    required
                    placeholder="TELEFONE"
                    id="d-telefone"
                    name="telefone"
                    onChange={this.handleChange}
                    value={this.state.message.telefone}
                  />
                  {!this.props.unit && (
                    <select
                      className="d-select-class"
                      required
                      id="d-select-unidade"
                      onChange={this.handleChange}
                      value={this.state.message.unidade}
                      // disabled={disableInputUnit}
                    >
                      <option value="" defaultValue selected>
                        UNIDADE
                      </option>
                      {this.renderUnits()}
                    </select>
                  )}
                  <div class="radio-toolbar">
                    <input
                      type="radio"
                      id="radioPresentialDiscount"
                      name="radioClassDiscount"
                      value="false"
                      checked={message.hasOnlineClasses === false}
                      onChange={this.setTypeModalityDiscount}
                    />
                    <label for="radioPresentialDiscount">AULA PRESENCIAL</label>

                    <input
                      type="radio"
                      id="radioOnlineDiscount"
                      name="radioClassDiscount"
                      value="true"
                      checked={message.hasOnlineClasses === true}
                      onChange={this.setTypeModalityDiscount}
                    />
                    <label for="radioOnlineDiscount">AULA ONLINE</label>
                  </div>
                  <div id="d-text-info">
                    <p>*O desconto não válido para a modalidade Online</p>
                  </div>
                  <div className="submit-container">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={recaptchaKey}
                      onChange={this.onChange}
                      size="normal"
                    />
                    <button
                      className={
                        this.state.enableSubmit === false ? "d-none" : "botao"
                      }
                      disabled={this.state.submited ? "disabled" : ""}
                    >
                      {this.state.submited ? "Aguarde..." : "Enviar"}
                    </button>
                    <div
                      className={
                        this.state.showPopUp === false
                          ? "d-none"
                          : "popup-discount-alert"
                      }
                    >
                      <div>Mensagem enviada!</div>
                    </div>
                  </div>
                </form>
              </div>
            </Accordion.Collapse>
          </div>
        </Accordion>
      </div>
    );
  }
}

export default DiscountForm;
