export const recaptchaKey = "6LeW2fYUAAAAAIfeoBqXsmrHhVooqFMqiKq3C_Rn";

export const googleMapsKey = "AIzaSyDeJWcyyPsuv_M-8WS66_ljwYvLTNxRTfI";

export const G_API_URL = `https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&&v=3.exp&libraries=geometry,drawing,places`;

const convertTolatLng = (latLng, title) => {
	return {
		latLng,
		title
	};
};

export const locationsList = {
	Mumbai: convertTolatLng("-9.652641, -35.7209077", "Mumbai"),
	Pune: convertTolatLng("-9.652856, -35.7170687", "Pune"),
	CurrentLocation: convertTolatLng("-9.652856, -35.7170687", "CurrentLocation"),
};
