exports = module.exports = require("../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("./assets/fonts/yellosun/yellosun.ttf"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("./assets/fonts/pintgram/Pintgram-Regular.ttf"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("./assets/fonts/nexa-rust.sans-black.otf"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("./assets/fonts/roboto/Roboto-Regular.ttf"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("./assets/fonts/roboto/Roboto-Medium.ttf"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("./assets/fonts/roboto/Roboto-Bold.ttf"));

// Module
exports.push([module.id, "@font-face {\n  font-family: \"yellow-sun\";\n  src: url(" + ___CSS_LOADER_URL___0___ + ");\n}\n@font-face {\n  font-family: \"pintgramm\";\n  src: url(" + ___CSS_LOADER_URL___1___ + ");\n}\n@font-face {\n  font-family: \"nexa\";\n  src: url(" + ___CSS_LOADER_URL___2___ + ");\n}\n@font-face {\n  font-family: \"roboto-regular\";\n  src: url(" + ___CSS_LOADER_URL___3___ + ");\n}\n@font-face {\n  font-family: \"roboto-medium\";\n  src: url(" + ___CSS_LOADER_URL___4___ + ");\n}\n@font-face {\n  font-family: \"roboto-bold\";\n  src: url(" + ___CSS_LOADER_URL___5___ + ");\n}", ""]);

