import axios from "axios";

let API_URL = "";
const env = process.env.REACT_APP_ENV;

switch (env) {
    case "production":
        API_URL = "https://www.iblsemlegenda.com.br/api";
        break;
    case "development":
        API_URL = "http://104.236.28.139/api";
        break;
    default:
        API_URL = "http://localhost:3000/api";
        break;
}

const api = axios.create({
    baseURL: API_URL,
});

export default api;
